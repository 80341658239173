import Vue from 'vue'

// axios
import axios from 'axios'

import jwtConfig from '@/jwtConfig'

// ** Third Party Components
import { toast } from '@/libs/toastification-generic'
// import AuthRequest from '@api/auths'

const authorization = 'Authorization'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  timeout: 200000,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
})

const interceptorRequest = async (config) => {
  const token = localStorage.getItem(jwtConfig.storageTokenKeyName)

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers[authorization] = `${jwtConfig.tokenType} ${token}`
  }

  // config.headers.common["X-Requested-With"] = "XMLHttpRequest";

  return config
}

const interceptorResponse = (response) => {
  const code = response ? response.status : null

  if (code === 200 || code === 201 || code === 202) {
    if (response.data && response.data.errors) {
      console.log(response.data.errors)
    }
  }

  return response
}

const interceptorError = (error) => {
  const notify = error.response.config.notify ?? true
  const code = error.response?.status ? error.response.status : 500

  if (error.message === 'Network Error') {
    console.log('Comprueba tu conexión a internet.')
    toast({
      title: 'Error de conexión',
      message: 'Comprueba tu conexión a internet.',
      variant: 'danger',
    })
  }

  if (code === 401) {
    if (notify) {
      toast({
        title: 'Error de autenticación',
        message: error.response.data.message,
        variant: 'danger',
      })
    }

    localStorage.removeItem(jwtConfig.storageUserDataKeyName)
    localStorage.removeItem(jwtConfig.storageTokenKeyName)
    localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

    window.location.href = window.location.origin
  }

  if (code === 403) {
    console.log('No tienes permisos para realizar esta acción.')
  }

  if (code === 404) {
    console.log('No se encontró el recurso solicitado.')
  }

  if (code === 422) {
    console.log('Los datos enviados no son válidos.')
    if (notify) {
      toast({
        title: error.response.data.message,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      })
    }
  }

  if (code === 500) {
    console.log('Error interno del servidor.')
    if (notify) {
      toast({
        title: 'Error interno del servidor',
        icon: 'XIcon',
        variant: 'danger',
      })
    }
  }

  if (code === 503) {
    console.log('Servicio no disponible.')
  }

  return Promise.reject(error)
}

http.interceptors.request.use(interceptorRequest, interceptorError)
http.interceptors.response.use(interceptorResponse, interceptorError)

Vue.prototype.$api = http

export default http
