export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/auth/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/auth/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/reset-password',
  //   name: 'auth-reset-password',
  //   component: () => import('@/views/auth/ResetPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/verify-2fa',
    name: 'verify-2fa',
    component: () => import('@/views/auth/Verify2FA.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/account/AccountSetting.vue'),
    meta: {
      pageTitle: 'Configuraciones',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Configuraciones',
          active: true,
        },
      ],
    },
  },

  // dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Analytics.vue'),
  },
  {
    path: '/page-statuses',
    name: 'page-statuses',
    component: () => import('@/views/dashboard/PageStatuses.vue'),
  },

  // Banner
  {
    path: '/banner',
    name: 'banner',
    component: () => import('@/views/banner/banner-list/BannerList.vue'),
  },
  {
    path: '/banner/preview/:id',
    name: 'banner-preview',
    component: () => import('@/views/banner/banner-preview/BannerPreview.vue'),
  },
  {
    path: '/banner/add/',
    name: 'banner-add',
    component: () => import('@/views/banner/banner-add/BannerAdd.vue'),
  },
  {
    path: '/banner/edit/:id',
    name: 'banner-edit',
    component: () => import('@/views/banner/banner-edit/BannerEdit.vue'),
  },

  // Content QR
  {
    path: '/content/qr',
    name: 'content-qr',
    component: () => import('@/views/content/ContentQR.vue'),
  },

  // Users
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/users-list/UsersList.vue'),
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/users/users-view/UsersView.vue'),
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users/users-edit/UsersEdit.vue'),
  },

  // Paises
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/countries/CountriesList.vue'),
  },

  // Idiomas
  {
    path: '/langs',
    name: 'langs',
    component: () => import('@/views/langs/LangsList.vue'),
  },

  // Categorias
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/categories/CategoriesList.vue'),
  },

  // Monedas
  {
    path: '/currencies',
    name: 'currencies',
    component: () => import('@/views/currencies/CurrenciesList.vue'),
  },

  // Cashiers
  {
    path: '/cashiers',
    name: 'cashiers',
    component: () => import('@/views/cashiers/CashiersList.vue'),
  },
  {
    path: '/cashiers/order',
    name: 'cashiers-order',
    component: () => import('@/views/cashiers/CashiersOrder.vue'),
  },
  {
    path: '/cashiers/limits',
    name: 'cashiers-limits',
    component: () => import('@/views/cashiers/CashiersLimits.vue'),
  },
  {
    path: '/cajeros',
    name: 'cashiers-limits-public',
    component: () => import('@/views/cashiers/CashiersLimitsPublic.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },

  // Challenge
  {
    path: '/challenge',
    name: 'challenge',
    component: () => import('@/views/challenge/ChallengeList.vue'),
  },
  {
    path: '/challenge/add/',
    name: 'challenge-add',
    component: () => import('@/views/challenge/challenge-add/ChallengeAdd.vue'),
  },
  {
    path: '/challenge/edit/:id',
    name: 'challenge-edit',
    component: () => import('@/views/challenge/challenge-edit/ChallengeEdit.vue'),
  },

  // Hide Elements
  {
    path: '/hide-elements',
    name: 'hide-elements',
    component: () => import('@/views/hide-elements/HideElementsList.vue'),
  },

  // Dominios
  {
    path: '/domains',
    name: 'domains',
    component: () => import('@/views/domains/DomainsList.vue'),
  },

  // Header
  {
    path: '/header',
    name: 'header',
    component: () => import('@/views/header/HeaderList.vue'),
  },
  {
    path: '/header/add/',
    name: 'header-add',
    component: () => import('@/views/header/header-form/HeaderForm.vue'),
  },
  {
    path: '/header/edit/:id',
    name: 'header-edit',
    component: () => import('@/views/header/header-form/HeaderForm.vue'),
  },
  {
    path: '/header/menu',
    name: 'header-menu',
    component: () => import('@/views/header/HeaderMenu.vue'),
  },

  // Link Promotions
  {
    path: '/link-promotions',
    name: 'link-promotions',
    component: () => import('@/views/link-promotions/LinkPromotionsList.vue'),
  },

  // Tournaments
  {
    path: '/tournaments',
    name: 'tournaments',
    component: () => import('@/views/tournaments/TournamentsList.vue'),
  },
  {
    path: '/tournaments/add/',
    name: 'tournaments-add',
    component: () => import('@/views/tournaments/tournaments-form/TournamentsForm.vue'),
  },
  {
    path: '/tournaments/edit/:id',
    name: 'tournaments-edit',
    component: () => import('@/views/tournaments/tournaments-form/TournamentsForm.vue'),
  },

  // Lottery
  {
    path: '/lottery',
    name: 'lottery',
    component: () => import('@/views/lottery/LotteryList.vue'),
  },
  {
    path: '/lottery/add/',
    name: 'lottery-add',
    component: () => import('@/views/lottery/lottery-form/LotteryForm.vue'),
  },
  {
    path: '/lottery/edit/:id',
    name: 'lottery-edit',
    component: () => import('@/views/lottery/lottery-form/LotteryForm.vue'),
  },
  {
    path: '/sorteo/:id?',
    name: 'lottery-game-play',
    component: () => import('@/views/lottery/LotteryGamePlay.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },

  // Participants
  {
    path: '/participants',
    name: 'participants',
    component: () => import('@/views/participants/ParticipantsList.vue'),
  },

  // Commerce
  {
    path: '/commerce',
    name: 'commerce',
    component: () => import('@/views/commerce/CommerceList.vue'),
  },

  // Categories Lottery
  {
    path: '/categories-award',
    name: 'categories-award',
    component: () => import('@/views/categories-award/CategoriesList.vue'),
  },

  // Web Clients
  {
    path: '/web-clients',
    name: 'web-clients',
    component: () => import('@/views/web-clients/WebClientsList.vue'),
  },
]
